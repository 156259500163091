<template>
  <div class="asset-warehouse-details">
    <div v-if="!errorShow">
      <loading :loadingShow="loadingShow"></loading>
      <div v-if="!loadingShow"
           class="expand-filter-wrapper content-main">
           <div class="filter-wrap">
             <expand-filter
                 :closeWidth="680"
                 :formData="formData"
                 marginBottom="0px"
                 @changeBtnFormType="changeBtnFormType"
                 @clickBtn="screenClickBtn"
                 @changeSel="changeSel">
               </expand-filter>
               <div class="button-line" v-hasPermi="['assetWarehouseManage:add']"></div>
               <div style="padding-bottom: 10px;">
                  <el-button type="primary" v-hasPermi="['assetWarehouseManage:add']" @click="handleAdd">添加</el-button>
               </div>
           </div>
        <table-data v-loading="loadingTable"
                    ref="table"
                    :config="table_config"
                    :tableData="tableList"
                    backgroundHeader="#FAFBFC">
          <template v-slot:operation="slotData">
            <el-button type="success"
                       size="mini"
                       @click="editWarehouse(slotData.data)"
                       v-hasPermi="['assetWarehouseManage:edit']">编辑
            </el-button>
            <el-button @click="categoryOpen(slotData.data)"
                       class="table-primary-button"
                       v-hasPermi="['assetWarehouseManage:category']">品类维护
            </el-button>
            <xk-button type="danger"
                       size="mini"
                       @click="deleteWarehouse(slotData.data)"
                       v-hasPermi="['assetWarehouseManage:delete']">删除
            </xk-button>
          </template>
        </table-data>
        <Pagination :total="pagingData.total"
                    :page.sync="pagingData.pageNum"
                    :limit.sync="pagingData.pageRow"
                    @pagination="getWarehouseList()" />
        <dialog-wrapper :dialogObj="dialogObj"
                        @handleClose="dialogClose">
          <el-form :model="dialogForm"
                   :rules="dialogRules"
                   ref="dialogRef"
                   label-width="92px"
                   label-position="right"
                   class="common-form">
            <el-form-item label="仓库名称"
                          prop="storeName">
              <el-input v-model="dialogForm.storeName"
                        placeholder="请输入仓库名称"
                        class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="仓库说明">
              <el-input v-model="dialogForm.storeDesc"
                        :rows="2"
                        type="textarea"
                        placeholder="请输入仓库说明"
                        class="input-width-medium">
              </el-input>
            </el-form-item>
            <el-form-item label="权限"
                          prop="peopleList">
              <el-cascader v-model="dialogForm.peopleList"
                           :options="workersData"
                           :collapse-tags="true"
                           filterable
                           :props="{label: 'name', value: 'id', multiple: true, emitPath: false}"
                           class="input-width-medium">
              </el-cascader>
            </el-form-item>
          </el-form>
          <div style="text-align: right">
              <el-button
                  type="enquiry"
                  @click="dialogClose"
              >取消
              </el-button>
              <el-button
                  type="primary"
                  @click="dialogSave"
              >确定
              </el-button>
          </div>
        </dialog-wrapper>
        <dialog-wrapper :dialogObj="categoryObj"
                        @handleClose="categoryClose">
          <el-form class="common-form">
            <el-form-item v-for="(v, i) in categoryForm" :key="i">
              <div class="input-bar">
                <el-input
                    v-model="v.categoryName"
                    placeholder="请输入品类名称"
                    clearable
                    class="input-width-medium"
                ></el-input>
                <div class="btn minus-btn"
                     @click="categoryFormMinus(i)"
                     v-if="categoryForm.length > 1">
                  <i class="el-icon-minus"></i>
                </div>
                <div class="btn plus-btn"
                     @click="categoryForm.push({
                                    id: '',
                                    schoolId: '',
                                    storeId: '',
                                    categoryName: ''
                                })"
                     v-if="i === categoryForm.length - 1">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
            </el-form-item>
          </el-form>
            <div style="text-align: right">
                <el-button
                    type="enquiry"
                    @click="categoryClose"
                >取消
                </el-button>
                <el-button
                    type="primary"
                    @click="categorySave"
                >确定
                </el-button>
            </div>
        </dialog-wrapper>
      </div>
    </div>
    <error v-else
           :errorShow="errorShow"></error>
  </div>
</template>

<script>
import { Pagination, Loading, Error, DialogWrapper, debounce } from "common-local"
import TableData from "./Sub/TableData"
import { CampusOAModel } from "@/models/CampusOA"
import { WarehouseModel } from "@/models/AssetWarehouseDetails"
import ExpandFilter from "./Sub/ExpandFilter.vue";
import onResize from "@/mixins/onResize"

export default {
  mixins: [onResize],
  name: "AssetWarehouseDetails",
  components: { ExpandFilter, TableData, Pagination, DialogWrapper, Loading, Error },
  data () {
    return {
      errorShow: false,
      loadingShow: false,
      tableList: [],
      workersData: [],
      formData: {
        btnFormType: true,
        data: [
          {
            type: "input",
            label: "",
            value: "",
            placeholder: "仓库名称",
            key: "name"
          },
          {
            type: "datePick",
            label: "",
            value: "",
              startPlaceholder: "创建开始日期",
              endPlaceholder: "创建结束日期",
            key: "date"
          },
          {
            type: "select",
            label: "",
            value: "",
              placeholder: "仓库权限",
            key: "name",
            list: []
          },
        ],
        btnList: [
          {
            type: "primary",
            text: "查询",
            fn: "primary",
            auth: ["assetWarehouseManage:list"]
          },
          {
            type: "enquiry",
            text: "重置",
            fn: "reset",
          },
        //   {
        //     type: "primary",
        //     text: "添加",
        //     fn: "add",
        //     auth: ["assetWarehouseManage:add"]
        //   }
        ],
      },
      loadingTable: true,
      table_config: {
        thead: [
          {
            label: "仓库名称",
            prop: "storeName",
            type: 'popoverText',
            align: 'center',
          },
          {
            label: "仓库说明",
            prop: "storeDesc",
            type: 'popoverText',
            align: 'center',
            type: "function",
            callBack: (row) => {
              return row.storeDesc || '-'
            }
          },
          {
            label: "创建时间",
            prop: "createTime",
            type: "function",
            align: 'center',
            callBack: (row) => {
              return row.createTime.slice(0,16)
            }
          },
          {
            label: "权限",
            prop: "storeManger",
            type: "tooltipColumn",
            align: 'center',
            splitSign: ",",
            labelWidth: '200px',
            className: 'text-spacing-reduction'
          },
          {
            label: "操作",
            type: "slot",
            slotName: "operation",
            className: 'text-spacing-reduction'
          }
        ],
        check: false,
        height: ''
      },
      dialogObj: {
        title: "添加/编辑",
        dialogVisible: false,
        width: "auto",
      },
      dialogForm: {
        storeName: "",
        storeDesc: "",
        peopleList: [],
      },
      dialogRules: {
        storeName: [
          { required: true, message: "请输入仓库名称", trigger: "blur" },
          { max: 100, message: "请输入100字符以内的仓库名称", trigger: "blur" }
        ],
        peopleList: [{ required: true, message: "请选择管理权限", trigger: "change" }]
      },
      pagingData: {
        total: 0,
        pageNum: 1,
        pageRow: 20
      },
      categoryObj: {
        title: "品类维护",
        dialogVisible: false,
        width: "auto",
      },
      categoryForm: [
        {
          id: "",
          schoolId: "",
          storeId: "",
          categoryName: ""
        }
      ],
      categoryId: "",
      checkedTeacher: ''
    }
  },
  created () {
    this.getWarehouseList()
    this.getIssueScopeByAccess()
    this.getTeacherList()
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initTableHeight()
      })
    })

  },
  methods: {
    initTableHeight () {
      console.log(this.$refs, 'this.$refs.table')
      // this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 135
    },
    // 保存品类维护
    categorySave () {
      let categoryList = this.categoryForm.filter(item => {
        return item.categoryName !== ""
      })
      this._fet("/school/schoolStoreCategory/submit", {
        schoolId: this.$store.state.schoolId,
        storeId: this.categoryId,
        list: categoryList.map(item => {
          return {
            id: item.id,
            schoolId: this.$store.state.schoolId,
            storeId: this.categoryId,
            categoryName: item.categoryName
          }
        })
      }).then(res => {
        this.handleRes(res, () => {
          if (res.data.data.length !== 0) {
            this.categoryObj.dialogVisible = false
          }
        })
      })
    },
    // 打开品类维护弹窗
    categoryOpen (data) {
      this._fet("/school/schoolStoreCategory/listByCondition", {
        schoolId: this.$store.state.schoolId,
        storeId: data.id
      }).then(res => {
        this.handleRes(res, () => {
          if (res.data.data.length !== 0) {
            this.categoryForm = []
            console.log(res);
            this.categoryForm = res.data.data.map(item => {
              return {
                id: item.id,
                schoolId: "",
                storeId: "",
                categoryName: item.categoryName
              }
            })
          }
        })
      })
      this.categoryId = data.id
      this.categoryObj.dialogVisible = true
    },
    // 删除仓库
    deleteWarehouse (data) {
        this.$confirm("请谨慎删除，仓库删除将同时删除仓库内的所有资产和资产流水，一旦删除数据无法恢复!", "删除确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const warehouseModel = new WarehouseModel()
        warehouseModel.warehouseDelete([{ id: data.id }]).then(res => {
          this.handleRes(res, () => {
            this.getWarehouseList(undefined, 1)
          })
        })
      }).catch(() => {
      })
    },
    // 编辑仓库
    editWarehouse (data) {
      this._fet("/school/schoolStore/info", {
        id: data.id
      }).then(res => {
        this.handleRes(res, () => {
          this.dialogForm.storeName = res.data.data.storeName
          this.dialogForm.storeDesc = res.data.data.storeDesc
          this.dialogForm.peopleList = res.data.data.mangerList
          this.dialogForm.id = res.data.data.id
          this.dialogObj.dialogVisible = true
        })
      })
    },
    // 关闭品类维护弹窗
    categoryClose () {
      this.categoryObj.dialogVisible = false
      this.categoryId = ""
      this.categoryForm = this.$options.data.call().categoryForm
    },
    // 删除品类维护项
    categoryFormMinus (index) {
      this.categoryForm.splice(index, 1)
    },
    // 获取仓库数据
    getWarehouseList (data, t) {
      this.loadingTable = true;
      if (t === 1) {
        this.pagingData.pageNum = 1
      }
      let objData = {},
            dateArr = [];

      this.formData.data.forEach((item) => {
        if (item.key === 'date') {
            dateArr = item.value;
        }
      })

      if (typeof data !== "undefined" || dateArr.length > 0) {
        if (data) {
            objData.storeName = data[0].value
        } else {
            objData.storeName = '';
        }

        if (Array.isArray(dateArr) && dateArr.length > 0) {
            objData.startTime = `${dateArr[0]} 00:00:00`;
            objData.endTime = `${dateArr[1]} 23:59:59`;
        } else {
            objData.startTime = undefined;
            objData.endTime = undefined;
        }

      } else {
        this.checkedTeacher = ""
        this.getTeacherList()
      }
      // 只有管理员可以筛选
      if (this.$store.state.isSuper !== '1') {
        this.checkedTeacher = ""
        this.getTeacherList()
      }
      this._fet("/school/schoolStore/list", {
        ...objData,
        schoolId: this.$store.state.schoolId,
        pageNum: this.pagingData.pageNum,
        pageRow: this.pagingData.pageRow,
        teacherId: this.checkedTeacher
      }).then(res => {
        this.handleRes(res, () => {
          this.pagingData.total = res.data.data.totalCount
          this.tableList = res.data.data.list
          this.loadingTable = false;
        })
      }).catch(err => {
        this.loadingTable = false;
      })
    },
    // 获取员工信息列表
    getIssueScopeByAccess () {
      const campusOAModel = new CampusOAModel()
      campusOAModel.getConditionByList({
        schoolId: this.$store.state.schoolId,
        applyPerson: "1"
      }).then((res) => {
        if (res.data.code === "200") {
          this.workersDataFor(res.data.data)
          this.workersData = res.data.data
        } else if (res.data && res.data.msg) {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 递归员工列表删除空数组
    workersDataFor (data) {
      data.forEach(item => {
        if (item.children && item.children.length !== 0) {
          this.workersDataFor(item.children)
        } else {
          if (item.key === "teacher") {
            item.children = undefined
          }
        }
      })
    },
    // 保存 || 编辑仓库
    dialogSave () {
      this.$refs.dialogRef.validate((valid) => {
        if (valid) {
          this._fet("/school/schoolStore/save", {
            ...this.dialogForm,
            schoolId: this.$store.state.schoolId,
          }).then(res => {
            this.handleRes(res, () => {
              this.getWarehouseList(undefined, 1)
              this.dialogClose()
            })
          })
        } else {
          return
        }
      })
    },
    // 筛选栏按钮方法
    screenClickBtn (data) {
      console.log(data, 'screenClickBtn');
      switch (data.item.fn) {
        case "primary":
          this.getWarehouseList(data.data, 1)
          break
        case "reset":
          this.formData = this.$options.data.call().formData
          this.getWarehouseList(undefined, 1)
          break
        case "add":
          this.dialogObj.dialogVisible = true
          break
        default:
          break
      }
    },
    handleAdd(){
        this.dialogObj.dialogVisible = true
    },
    // 弹窗关闭回调
    dialogClose () {
      this.dialogObj.dialogVisible = false
      this.dialogForm = this.$options.data.call().dialogForm
    },
    // 接口请求结果处理
    handleRes (res, fn) {
      if (res.data.code === '200') {
        fn()
      } else if (res.data && res.data.msg) {
        this.$message.error(res.data.msg)
      }
    },
    /**
     * @Descripttion: 获取教师id
     * @DoWhat: 获取教师id
     * @UseScenarios: 获取教师id
     * @Attention:
     * @Author: lyx
     * @Date: 2022-11-29 09:55:18
     */
    getTeacherList () {
      this._fet('/school/schoolTeacher/listByCondition', {
        schoolId: this.$store.state.schoolId
      }).then((res) => {
        this.handleRes(res, () => {
          this.formData.data[2].list = res.data.data.map(i => {
            return { label: i.teacherName, value: i.id }
          })
        })
      })
    },
    /**
     * @Descripttion: 获取当前选中的教师
     * @DoWhat: 获取当前选中的教师
     * @UseScenarios: 获取当前选中的教师
     * @Attention:
     * @Author: lyx
     * @Date: 2022-11-29 10:31:00
     * @param {*} data
     */
    changeSel (data) {
      let obj = []
      if (typeof data !== 'undefined') {
        obj = data.list.find(item => {
          return item.value == data.value
        })
        if (obj) {
          this.checkedTeacher = obj.value
        } else {
          this.checkedTeacher = ''
        }
      }
      console.log(this.checkedTeacher,'this.checkedTeacher');
    }

  }
}
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
.input-bar {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  border-radius: 4px;
  align-items: center;
  border: solid 1px #e6e6e6;

  /deep/ .el-input__inner {
    border: none;
  }
}
::v-deep .el-form--inline .el-form-item {
  &:nth-child(3) {
    //margin-left: 20px !important;
  }
  .btn {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    padding: 0 10px;
    border-left: solid 1px #e6e6e6;

    i {
      display: block;
      cursor: pointer;
      line-height: 28px;
      text-align: center;
      border-radius: 50%;
    }
  }

  .plus-btn i {
    color: #3C7FFF;
    background-color: #d5e4f2;
  }

  .minus-btn i {
    color: #f56c6c;
    background-color: #faebeb;
  }
}
</style>
